import DashBoard from "@/components/DashBoard";
import Admin from "@/components/admin/Admin";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Toaster } from "@/components/ui/toaster"

import './index.css'
import 'react-toastify/dist/ReactToastify.css';

const elementsPublic = document.querySelectorAll(".cv-wallet-user");
const elementsAdmin = document.querySelectorAll(".cv-wallet-admin");

// Render DashBoard for public elements
elementsPublic.forEach((element) => {
  ReactDOM.createRoot(element).render(
    <React.StrictMode>
      <Toaster />
      <DashBoard />
    </React.StrictMode>
  );
});

// Render Admin for admin elements
elementsAdmin.forEach((element) => {
  ReactDOM.createRoot(element).render(
    <React.StrictMode>
      <Toaster />
      <Admin />
    </React.StrictMode>
  );
});
