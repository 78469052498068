import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { Cross2Icon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "cv-fixed cv-inset-0 cv-z-50 cv-bg-black/80 cv- data-[state=open]:cv-animate-in data-[state=closed]:cv-animate-out data-[state=closed]:cv-fade-out-0 data-[state=open]:cv-fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "cv-fixed cv-left-[50%] cv-top-[50%] cv-z-50 cv-grid cv-w-full cv-max-w-lg cv-translate-x-[-50%] cv-translate-y-[-50%] cv-gap-4 cv-border cv-bg-background cv-p-6 cv-shadow-lg cv-duration-200 data-[state=open]:cv-animate-in data-[state=closed]:cv-animate-out data-[state=closed]:cv-fade-out-0 data-[state=open]:cv-fade-in-0 data-[state=closed]:cv-zoom-out-95 data-[state=open]:cv-zoom-in-95 data-[state=closed]:cv-slide-out-to-left-1/2 data-[state=closed]:cv-slide-out-to-top-[48%] data-[state=open]:cv-slide-in-from-left-1/2 data-[state=open]:cv-slide-in-from-top-[48%] sm:cv-rounded-lg",
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="cv-absolute cv-right-4 cv-top-4 cv-rounded-sm cv-opacity-70 cv-ring-offset-background cv-transition-opacity hover:cv-opacity-100 focus:cv-outline-none focus:cv-ring-2 focus:cv-ring-ring focus:cv-ring-offset-2 disabled:cv-pointer-events-none data-[state=open]:cv-bg-accent data-[state=open]:cv-text-muted-foreground">
        <Cross2Icon className="cv-h-4 cv-w-4" />
        <span className="cv-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "cv-flex cv-flex-col cv-space-y-1.5 cv-text-center sm:cv-text-left",
      className
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "cv-flex cv-flex-col-reverse sm:cv-flex-row sm:cv-justify-end sm:cv-space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "cv-text-lg cv-font-semibold cv-leading-none cv-tracking-tight",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("cv-text-sm cv-text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
