import { Button } from "@/components/ui/button";
import { User } from "./interfaces";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import formatCurrency from "@/components/formatCurrency";

interface UserTableProps {
    users: User[];
    setSelectedUser: (user: User) => void;
    fetchUserTransactions: (userID: number, page: number) => void;
    setShowDialog: (show: boolean) => void;
    setDialogType: (type: 'update' | 'history') => void;
}

const UserTable = ({ users, setSelectedUser, fetchUserTransactions, setShowDialog, setDialogType }: UserTableProps) => {
    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>ID</TableHead>
                    <TableHead>Tên người dùng</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Số điện thoại</TableHead>
                    <TableHead>Tên hiển thị</TableHead>
                    <TableHead>Số dư</TableHead>
                    <TableHead>Hành động</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {users.map(user => (
                    <TableRow key={user.userID}>
                        <TableCell>{user.userID}</TableCell>
                        <TableCell>{user.userName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>{user.displayName}</TableCell>
                        <TableCell>{formatCurrency(user.totalAmount)}</TableCell>
                        <TableCell>

                            <div className="cv-flex cv-gap-1 cv-items-center">
                                <Button size={'sm'} variant={'outline'} onClick={() => { setSelectedUser(user); setDialogType('update'); setShowDialog(true); }}>Chỉnh sửa số dư</Button>
                                <Button size={'sm'} variant={'outline'} onClick={() => { setSelectedUser(user); fetchUserTransactions(user.userID, 1) }}>Xem lịch sử</Button>
                            </div>

                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default UserTable;
