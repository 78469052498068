import { useState } from "react";
import SaveOptions from "@/components/admin/SaveOptions";
import TableUsers from "@/components/admin/TableUsers";
import { Button } from "@/components/ui/button";

const Admin = () => {
    const [activeTab, setActiveTab] = useState("users");

    return (
        <div>
            <div className="cv-flex cv-gap-4 cv-mb-4">
                <Button variant={activeTab === "users" ? "default" : "outline"} onClick={() => setActiveTab("users")}>Quản lý người dùng</Button>
                <Button variant={activeTab === "options" ? "default" : "outline"} onClick={() => setActiveTab("options")}>Cài đặt chung</Button>
            </div>
            <div>
                {activeTab === "users" && <TableUsers />}
                {activeTab === "options" && <SaveOptions />}
            </div>
        </div>
    );
};

export default Admin;
