import axios from "axios";

export const getUserData = async (userID: number): Promise<any> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_get_user_info');
    formData.append('userID', userID.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data;
    } catch (error) {
        console.error('Error getting options:', error);
        throw error;
    }
};


export const getUserTransitions = async (userID: number): Promise<any> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_get_transaction_history');
    formData.append('userID', userID.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data;
    } catch (error) {
        console.error('Error getting options:', error);
        throw error;
    }
};


export const recharge = async (userID: number, amount: number) => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_recharge');
    formData.append('userID', userID.toString());
    formData.append('amount', amount.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data.data;
    } catch (error) {
        console.error('Error getting options:', error);
        throw error;
    }
};

export const updateTransactionStatus = async (transaction_id: number): Promise<any> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_update_transaction_status');
    formData.append('transaction_id', transaction_id.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data.data;
    } catch (error) {
        console.error('Error updating transaction status:', error);
        throw error;
    }
};


