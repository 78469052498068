import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { User, UserListResponse, TransactionHistoryResponse } from "./interfaces";
import { getUsersList, getUserTransactionHistory, updateUserAmount } from "@/components/admin/adminActions";
import UserTable from "./UserTable";
import UserDialog from "./UserDialog";
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
} from "@/components/ui/pagination";

const TableUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState<'update' | 'history'>('update');
    const [transactions, setTransactions] = useState<TransactionHistoryResponse | null>(null);
    const [amount, setAmount] = useState<number>(0);
    const [actionType, setActionType] = useState<'add' | 'subtract'>('add');

    const fetchUsers = async (page: number) => {
        setLoading(true);
        try {
            const response: UserListResponse = await getUsersList(page);
            setUsers(response.users);
            setTotalPages(response.total_pages);
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        } finally {
            setLoading(false);
        }
    };

    const fetchUserTransactions = async (userID: number, page: number) => {
        try {
            const response: TransactionHistoryResponse = await getUserTransactionHistory(userID, page);
            setTransactions(response);
            console.log(response.transactions);
            console.log(showDialog);
            setShowDialog(true);
            setDialogType('history');
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        }
    };

    useEffect(() => {
        fetchUsers(page);
    }, [page]);

    const handleUpdateAmount = async () => {
        if (!selectedUser) return;
        try {
            const response = await updateUserAmount(selectedUser.userID, amount, actionType);
            if (response.success) {
                toast.success("Cập nhật số dư thành công!");
                fetchUsers(page);
                setShowDialog(false);
            } else {
                toast.error(response.message || "Cập nhật số dư thất bại!");
            }
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        }
    };

    const renderPagination = () => (
        <Pagination>
            <PaginationContent>
                {Array.from({ length: totalPages }).map((_, i) => (
                    <PaginationItem key={i}>
                        <PaginationLink
                            href="#"
                            isActive={i + 1 === page}
                            onClick={() => setPage(i + 1)}
                        >
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
            </PaginationContent>
        </Pagination>
    );

    return (
        <>
            <ToastContainer />
            <div className="cv-shadow cv-rounded cv-p-8">
                <h2 className="cv-text-2xl cv-mb-4">Danh sách người dùng</h2>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <UserTable users={users} setSelectedUser={setSelectedUser} fetchUserTransactions={fetchUserTransactions} setShowDialog={setShowDialog} setDialogType={setDialogType} />
                        <div className="cv-mt-4 cv-flex cv-justify-center">
                            {renderPagination()}
                        </div>
                    </>
                )}
            </div>
            {selectedUser && (
                <UserDialog
                    open={showDialog}
                    onClose={() => setShowDialog(false)}
                    dialogType={dialogType}
                    user={selectedUser}
                    amount={amount}
                    setAmount={setAmount}
                    handleUpdateAmount={handleUpdateAmount}
                    transactions={transactions}
                    fetchUserTransactions={fetchUserTransactions}
                    setActionType={setActionType}
                />
            )}
        </>
    );
};

export default TableUsers;
