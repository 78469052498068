import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { saveOptions, getWalletOptions } from "@/components/admin/adminActions";
import { Options } from "./interfaces";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";

const SaveOptions = () => {
    const [options, setOptions] = useState<Options>({
        walletName: '',
        paymentMethodName: '',
        paymentMethodDescription: '',
        bankToken: '',
        bankID: '',
        telegramEnabled: false,
        telegramToken: '',
        telegramGroup: ''
    });
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const fetchedOptions = await getWalletOptions();
                if (fetchedOptions.success) {
                    const data = fetchedOptions.data;
                    // Ensure all fields are defined
                    setOptions({
                        walletName: data.wallet_name || '',
                        paymentMethodName: data.payment_method_name || '',
                        paymentMethodDescription: data.payment_method_description || '',
                        bankToken: data.bank_token || '',
                        bankID: data.bankID || '',
                        telegramEnabled: data.telegram || false,
                        telegramToken: data.telegram_token || '',
                        telegramGroup: data.telegram_group || ''
                    });
                } else {
                    toast.error("Đã xảy ra lỗi khi lấy tùy chọn, vui lòng thử lại!");
                }
            } catch (error) {
                toast.error("Đã xảy ra lỗi khi lấy tùy chọn, vui lòng thử lại!");
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setOptions({ ...options, [name]: value });
    };

    const handleSwitchChange = (checked: boolean) => {
        setOptions({ ...options, telegramEnabled: checked });
    };

    const handleSaveOptions = async () => {
        try {
            const response = await saveOptions(options);
            if (response.success) {
                toast.success("Lưu tùy chọn thành công!");
            } else {
                toast.error(response.message || "Lưu tùy chọn thất bại!");
            }
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ToastContainer />
            <div className="cv-shadow cv-rounded cv-p-8">
                <h2 className="cv-text-2xl cv-mb-4">Lưu Tùy Chọn</h2>
                <div className="cv-grid cv-grid-cols-1 cv-gap-4">
                    <div>
                        <label>Tên ví</label>
                        <Input
                            type="text"
                            name="walletName"
                            value={options.walletName}
                            onChange={handleInputChange}
                            placeholder="Tên ví"
                        />
                    </div>
                    <div>
                        <label>Tên phương thức thanh toán</label>
                        <Input
                            type="text"
                            name="paymentMethodName"
                            value={options.paymentMethodName}
                            onChange={handleInputChange}
                            placeholder="Tên phương thức thanh toán"
                        />
                    </div>
                    <div>
                        <label>Mô tả phương thức thanh toán</label>
                        <Input
                            type="text"
                            name="paymentMethodDescription"
                            value={options.paymentMethodDescription}
                            onChange={handleInputChange}
                            placeholder="Mô tả phương thức thanh toán"
                        />
                    </div>
                    <div>
                        <label>Bank Token</label>
                        <Input
                            type="text"
                            name="bankToken"
                            value={options.bankToken}
                            onChange={handleInputChange}
                            placeholder="Bank Token"
                        />
                    </div>
                    <div>
                        <label>Bank ID</label>
                        <Input
                            type="text"
                            name="bankID"
                            value={options.bankID}
                            onChange={handleInputChange}
                            placeholder="Bank ID"
                        />
                    </div>
                    <div className="cv-flex cv-items-center cv-gap-1">
                        <label>Bật thông báo Telegram</label>
                        <Switch
                            name="telegramEnabled"
                            checked={options.telegramEnabled}
                            onCheckedChange={handleSwitchChange}
                        />
                    </div>
                    {options.telegramEnabled && (
                        <>
                            <div>
                                <label>Telegram Token</label>
                                <Input
                                    type="text"
                                    name="telegramToken"
                                    value={options.telegramToken}
                                    onChange={handleInputChange}
                                    placeholder="Telegram Token"
                                />
                            </div>
                            <div>
                                <label>Telegram Group</label>
                                <Input
                                    type="text"
                                    name="telegramGroup"
                                    value={options.telegramGroup}
                                    onChange={handleInputChange}
                                    placeholder="Telegram Group"
                                />
                            </div>
                        </>
                    )}
                    <div>
                        <Button onClick={handleSaveOptions}>Lưu tùy chọn</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaveOptions;
