import * as React from "react"
import { Cross2Icon } from "@radix-ui/react-icons"
import * as ToastPrimitives from "@radix-ui/react-toast"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "cv-fixed cv-top-0 cv-z-[100] cv-flex cv-max-h-screen cv-w-full cv-flex-col-reverse cv-p-4 sm:cv-bottom-0 sm:cv-right-0 sm:cv-top-auto sm:cv-flex-col md:cv-max-w-[420px]",
      className
    )}
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const toastVariants = cva(
  "cv-group cv-pointer-events-auto cv-relative cv-flex cv-w-full cv-items-center cv-justify-between cv-space-x-2 cv-overflow-hidden cv-rounded-md cv-border cv-p-4 cv-pr-6 cv-shadow-lg cv-transition-all data-[swipe=cancel]:cv-translate-x-0 data-[swipe=end]:cv-translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:cv-translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:cv-transition-none data-[state=open]:cv-animate-in data-[state=closed]:cv-animate-out data-[swipe=end]:cv-animate-out data-[state=closed]:cv-fade-out-80 data-[state=closed]:cv-slide-out-to-right-full data-[state=open]:cv-slide-in-from-top-full data-[state=open]:sm:cv-slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "cv-border cv-bg-background cv-text-foreground",
        destructive:
          "cv-destructive cv-group cv-border-destructive cv-bg-destructive cv-text-destructive-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    />
  )
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "cv-inline-flex cv-h-8 cv-shrink-0 cv-items-center cv-justify-center cv-rounded-md cv-border cv-bg-transparent cv-px-3 cv-text-sm cv-font-medium cv-transition-colors hover:cv-bg-secondary focus:cv-outline-none focus:cv-ring-1 focus:cv-ring-ring disabled:cv-pointer-events-none disabled:cv-opacity-50 group-[.destructive]:cv-border-muted/40 group-[.destructive]:hover:cv-border-destructive/30 group-[.destructive]:hover:cv-bg-destructive group-[.destructive]:hover:cv-text-destructive-foreground group-[.destructive]:focus:cv-ring-destructive",
      className
    )}
    {...props}
  />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "cv-absolute cv-right-1 cv-top-1 cv-rounded-md cv-p-1 cv-text-foreground/50 cv-opacity-0 cv-transition-opacity hover:cv-text-foreground focus:cv-opacity-100 focus:cv-outline-none focus:cv-ring-1 group-hover:cv-opacity-100 group-[.destructive]:cv-text-red-300 group-[.destructive]:hover:cv-text-red-50 group-[.destructive]:focus:cv-ring-red-400 group-[.destructive]:focus:cv-ring-offset-red-600",
      className
    )}
    toast-close=""
    {...props}
  >
    <Cross2Icon className="cv-h-4 cv-w-4" />
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("cv-text-sm cv-font-semibold [&+div]:cv-text-xs", className)}
    {...props}
  />
))
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("cv-text-sm cv-opacity-90", className)}
    {...props}
  />
))
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
}
