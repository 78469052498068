import * as React from "react"

import { cn } from "@/lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "cv-flex cv-h-9 cv-w-full cv-rounded-md cv-border cv-border-input cv-bg-transparent cv-px-3 cv-py-1 cv-text-sm cv-shadow-sm cv-transition-colors file:cv-border-0 file:cv-bg-transparent file:cv-text-sm file:cv-font-medium placeholder:cv-text-muted-foreground focus-visible:cv-outline-none focus-visible:cv-ring-1 focus-visible:cv-ring-ring disabled:cv-cursor-not-allowed disabled:cv-opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
