import Header from "@/components/Header";
import TableTransitions from "@/components/TableTransitions";
import UserAction from "@/components/UserAction";
import { getUserData, getUserTransitions } from "@/services/actions";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function DashBoard() {
    const [data, setData] = useState<any | null>(null);
    const [transitions, setTransitions] = useState<any | null>(null);
    const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
    const [loadingTransitions, setLoadingTransitions] = useState<boolean>(false);

    const userIdElement = document.getElementById('user_id') as HTMLInputElement | null;
    const userID = userIdElement ? parseInt(userIdElement.value) : null;

    const fetchUserData = async () => {
        if (userID === null) {
            toast.error("User ID is not available");
            return;
        }
        setLoadingUserData(true);
        try {
            const userData = await getUserData(userID);
            setData(userData.data);
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        } finally {
            setLoadingUserData(false);
        }
    };

    const fetchUserTransitions = async () => {
        if (userID === null) {
            toast.error("User ID is not available");
            return;
        }
        setLoadingTransitions(true);
        try {
            const userTransitions = await getUserTransitions(userID);
            setTransitions(userTransitions.data);
        } catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        } finally {
            setLoadingTransitions(false);
        }
    };

    useEffect(() => {
        fetchUserData();
        fetchUserTransitions();
    }, []);

    const handleRechargeSuccess = () => {
        fetchUserData();
        toast.success("Nạp tiền thành công!");
        fetchUserTransitions();
    };

    return (
        <>
            <ToastContainer />
            {loadingUserData || loadingTransitions ? (
                <div>Loading...</div>
            ) : (
                <div className="cv-shadow cv-rounded cv-p-8">
                    {data && <Header data={data} />}
                    <UserAction userID={userID} onRechargeSuccess={handleRechargeSuccess} />
                    {transitions && <TableTransitions transitions={transitions.transactions} />}
                </div>
            )}
        </>
    );
}
