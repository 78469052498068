import formatCurrency from "@/components/formatCurrency";
import { CiUser } from "react-icons/ci";

interface HeaderProps {
    data: {
        displayName: string;
        totalAmount: number;
    };
}


export default function Header({ data }: HeaderProps) {
    return (
        <div className="cv-grid cv-grid-cols-1 lg:cv-grid-cols-2 cv-gap-4">
            <div className="cv-flex cv-p-4 cv-items-center cv-gap-2 cv-bg-slate-50">
                <CiUser size={52} />
                <div className="">
                    <div className="">Xin chào!</div>
                    <div className="cv-font-semibold cv-text-xl cv-text-red-500">{data.displayName}</div>
                </div>
            </div>
            <div className="cv-rounded cv-p-4 cv-bg-slate-50">
                <div className="cv-flex cv-flex-col cv-gap-2">
                    <span>Số dư tài khoản của bạn:</span>
                    <span className="cv-text-2xl cv-text-blue-900 cv-font-bold">{formatCurrency(data.totalAmount)}</span>
                </div>
            </div>
        </div>
    );
}
