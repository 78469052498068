import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { recharge, updateTransactionStatus } from "@/services/actions";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CiCreditCard1 } from "react-icons/ci";
import { IoCopyOutline } from "react-icons/io5";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface UserActionProps {
    userID: number;
    onRechargeSuccess: () => void;
}

export default function UserAction({ userID, onRechargeSuccess }: UserActionProps) {
    const [amount, setAmount] = useState<number | null>(null);
    const [data, setData] = useState<any | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (amount !== null && loading) {
            handleRecharge();
        }
    }, [amount]);

    const handleRecharge = async () => {
        if (amount === null) {
            toast.error("Vui lòng nhập số tiền cần nạp!");
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await recharge(userID, amount);
            setData(response.order_response);
            setShowDialog(true);
            pollTransactionStatus(response.transaction_id);
        } catch (error) {
            console.error('Error fetching options:', error);
            toast.error("Đã xảy ra lỗi, vui lòng thử lại!");
        } finally {
            setLoading(false);
        }
    };

    const pollTransactionStatus = async (transaction_id: number) => {
        const interval = setInterval(async () => {
            try {
                const response = await updateTransactionStatus(transaction_id);
                if (response.order_status.status === 3 || response.order_status.status == 4) {
                    clearInterval(interval);
                    onRechargeSuccess();
                    setShowDialog(false);
                }
            } catch (error) {
                console.error('Error updating transaction status:', error);
            }
        }, 5000);
    };

    const quickRecharge = (quickAmount: number) => {
        setLoading(true);
        setAmount(quickAmount);
        handleRecharge();
    };

    return (
        <>
            <ToastContainer />
            <div className="cv-grid cv-grid-cols-3 cv-gap-4 cv-mt-4">
                <span>Nạp tiền vào tài khoản</span>
                <Input
                    type="number"
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    placeholder="Số tiền cần nạp"
                />
                <Button onClick={handleRecharge} variant={'destructive'} className="">
                    {loading ? 'Đang xử lý...' : 'Nạp ngay'}
                </Button>
            </div>
            <div className="cv-grid cv-grid-cols-6 cv-gap-2 cv-mt-4">
                <div className="cv-col-span-2">
                    <span className="cv-w-full">Hoặc nạp nhanh với: </span>
                </div>
                <Button onClick={() => quickRecharge(50000)}>50.000 đ</Button>
                <Button onClick={() => quickRecharge(100000)}>100.000 đ</Button>
                <Button onClick={() => quickRecharge(200000)}>200.000 đ</Button>
                <Button onClick={() => quickRecharge(500000)}>500.000 đ</Button>
            </div>
            {data && showDialog && (
                <Dialog open={showDialog} onOpenChange={setShowDialog}>
                    <DialogContent className="sm:cv-max-w-screen-md">
                        <DialogHeader>
                            <DialogTitle>
                                <div className="cv-flex cv-items-center cv-gap-1 cv-border-b-2 cv-pb-2">
                                    <CiCreditCard1 />
                                    <span>Nạp tiền vào tài khoản</span>
                                </div>
                            </DialogTitle>
                            <DialogDescription>Chuyển khoản theo thông tin dưới đây để tiến hành nạp tiền</DialogDescription>
                        </DialogHeader>
                        <div className="cv-grid cv-grid-cols-12 cv-items-center">
                            <div className="cv-col-span-5">
                                <img className="cv-w-full" src={data.urls.qrcode_url} alt="QR Code" />
                            </div>
                            <div className="cv-col-span-7">
                                <div className="cv-flex cv-items-center cv-gap-1 cv-mb-2">
                                    <span className="cv-flex-grow cv-h-[2px] cv-bg-gray-500"></span>
                                    <span className="cv-flex-none cv-text-[12px]">THÔNG TIN CHUYỂN KHOẢN</span>
                                    <span className="cv-flex-grow cv-h-[2px] cv-bg-gray-500"></span>
                                </div>
                                <div className="cv-grid cv-grid-cols-12 cv-gap-2">
                                    <strong className="cv-col-span-4">Ngân hàng:</strong>
                                    <div className="cv-col-span-7">
                                        <span className="cv-border cv-p-1 cv-px-2 cv-rounded">{data.bank_shortName}</span>
                                    </div>
                                    <strong className="cv-col-span-4">Nội dung:</strong>
                                    <div className="cv-col-span-7">
                                        <span className="cv-border cv-p-1 cv-px-2 cv-rounded">{data.check_code}</span>
                                        <button className="cv-p-2 cv-m-0"><IoCopyOutline /></button>
                                    </div>
                                    <strong className="cv-col-span-4">Số tiền:</strong>
                                    <div className="cv-col-span-7">
                                        <span className="cv-border cv-p-1 cv-px-2 cv-rounded">{data.amount}</span>
                                        <button className="cv-p-2 cv-m-0"><IoCopyOutline /></button>
                                    </div>
                                    <strong className="cv-col-span-4">Số tài khoản:</strong>
                                    <div className="cv-col-span-7">
                                        <span className="cv-border cv-p-1 cv-px-2 cv-rounded">{data.accno}</span>
                                        <button className="cv-p-2 cv-m-0"><IoCopyOutline /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button onClick={() => setShowDialog(false)}>Đóng</Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
