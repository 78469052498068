import formatCurrency from "@/components/formatCurrency";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";

interface TransitionsProps {
    transitions: any[];
}

export default function TableTransitions({ transitions }: TransitionsProps) {
    console.log(transitions);
    return (
        <div className="cv-mt-8">
            <Table>
                <TableCaption>Lịch sử giao dịch.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Nội dung</TableHead>
                        <TableHead>Ngày tạo</TableHead>
                        <TableHead>Số tiền</TableHead>
                        <TableHead>Loại giao dịch</TableHead>
                        <TableHead className="text-right">Trạng thái</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {transitions.map((item: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell className="font-medium">{item.ID}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.expired_date}</TableCell>
                            <TableCell>
                                {formatCurrency(item.amount)}
                            </TableCell>
                            <TableCell>
                                {item.type}
                            </TableCell>
                            <TableCell className="text-right">{item.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
