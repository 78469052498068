import axios from 'axios';
import { UserListResponse, UpdateAmountResponse, TransactionHistoryResponse } from './interfaces';

export const getUsersList = async (page = 1, limit = 10): Promise<UserListResponse> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_get_users_list');
    formData.append('page', page.toString());
    formData.append('limit', limit.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching users list:', error);
        throw error;
    }
};

export const updateUserAmount = async (userID: number, amount: number, type: 'add' | 'subtract'): Promise<UpdateAmountResponse> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_update_user_amount');
    formData.append('userID', userID.toString());
    formData.append('amount', amount.toString());
    formData.append('type', type);
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data;
    } catch (error) {
        console.error('Error updating user amount:', error);
        throw error;
    }
};

export const getUserTransactionHistory = async (userID: number, page: number): Promise<TransactionHistoryResponse> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_get_transaction_history');
    formData.append('userID', userID.toString());
    formData.append('page', page.toString());
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data.data;
    } catch (error) {
        console.error('Error getting user transaction history:', error);
        throw error;
    }
};


import { SaveOptionsResponse, Options } from './interfaces';

export const saveOptions = async (options: Options): Promise<SaveOptionsResponse> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_save_wallet_options');
    formData.append('wallet_name', options.walletName);
    formData.append('payment_method_name', options.paymentMethodName);
    formData.append('payment_method_description', options.paymentMethodDescription);
    formData.append('bank_token', options.bankToken);
    formData.append('bankID', options.bankID);
    formData.append('telegram', options.telegramEnabled.toString());
    formData.append('telegram_token', options.telegramToken);
    formData.append('telegram_group', options.telegramGroup);
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data;
    } catch (error) {
        console.error('Error saving options:', error);
        throw error;
    }
};


export const getWalletOptions = async (): Promise<Options> => {
    const formData = new FormData();
    formData.append('action', 'cv_wallet_get_wallet_options');
    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData);
        return response.data;
    } catch (error) {
        console.error('Error getting wallet options:', error);
        throw error;
    }
};