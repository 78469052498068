import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { User, TransactionHistoryResponse } from "./interfaces";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import formatCurrency from "@/components/formatCurrency";
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
} from "@/components/ui/pagination";

interface UserDialogProps {
    open: boolean;
    onClose: () => void;
    dialogType: 'update' | 'history';
    user: User;
    amount: number;
    setAmount: (amount: number) => void;
    handleUpdateAmount: () => void;
    transactions: TransactionHistoryResponse | null;
    fetchUserTransactions: (userID: number, page: number) => void;
    setActionType: (type: 'add' | 'subtract') => void;
}

const UserDialog = ({
    open,
    onClose,
    dialogType,
    user,
    amount,
    setAmount,
    handleUpdateAmount,
    transactions,
    fetchUserTransactions,
    setActionType
}: UserDialogProps) => {
    const renderTransactionPagination = () => (
        transactions && (
            <Pagination>
                <PaginationContent>
                    {Array.from({ length: transactions.total_pages }).map((_, i) => (
                        <PaginationItem key={i}>
                            <PaginationLink
                                href="#"
                                isActive={i + 1 === 1}
                                onClick={() => fetchUserTransactions(user.userID, i + 1)}
                            >
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                </PaginationContent>
            </Pagination>
        )
    );

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:cv-max-w-screen-md">
                {dialogType === 'update' ? (
                    <>
                        <DialogHeader>
                            <DialogTitle>Cập nhật số dư cho {user.displayName}</DialogTitle>
                        </DialogHeader>
                        <div className="cv-mb-4">
                            <Input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(Number(e.target.value))}
                                placeholder="Số tiền"
                            />
                        </div>
                        <DialogFooter>
                            <Button variant={'outline'} onClick={() => { setActionType('add'); handleUpdateAmount(); }}>Cộng</Button>
                            <Button variant={'destructive'} onClick={() => { setActionType('subtract'); handleUpdateAmount(); }}>Trừ</Button>
                        </DialogFooter>
                    </>
                ) : (
                    <>
                        <DialogHeader>
                            <DialogTitle>Lịch sử giao dịch của {user.displayName}</DialogTitle>
                        </DialogHeader>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>ID</TableHead>
                                    <TableHead>Mô tả</TableHead>
                                    <TableHead>Ngày tạo</TableHead>
                                    <TableHead>Số tiền</TableHead>
                                    <TableHead>Trạng thái</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {transactions?.transactions.map(transaction => (
                                    <TableRow key={transaction.ID}>
                                        <TableCell>{transaction.ID}</TableCell>
                                        <TableCell>{transaction.description}</TableCell>
                                        <TableCell>{transaction.create_date}</TableCell>
                                        <TableCell>{formatCurrency(transaction.amount)}</TableCell>
                                        <TableCell>{transaction.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className="cv-mt-4 cv-flex cv-justify-center">
                            {renderTransactionPagination()}
                        </div>
                        <DialogFooter>
                            <Button onClick={onClose}>Đóng</Button>
                        </DialogFooter>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default UserDialog;
